import React from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    const { t } = useTranslation();

        return (
        <div>
            <Helmet>
                <title>Kļūda 404 | BIO Radošais Projekts</title>
            </Helmet>
            <div className="heading">
                <div className="unicode-symbol">⚠</div>
                <h1 className="display-5 title2">Kļūda 404!</h1>
                <p className="subtitle2">
        Nevar atrast mājaslapu uz hostinga!
        <br />
        Iespējams, ka jūs ievadījāt nepareizu adresi.
      </p>
                <br /><br />
            </div>
            </div>
        );
};

export default NotFound;