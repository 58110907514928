const Jautajumi = [
    {
        jautId: 0,
        jautText: 'Esi saslimis ar masalām. Vai pēc atveseļošanās no masalām gūsi imunitāti?',
        answers: ['Nē, tas nav iespējams.', 'Jā, imunitāti gūšu.', 'Nezinu, varbūt.'],
        correctAnswerIndex: 1,
    },
    {
        jautId: 1,
        jautText: 'Vai, saslimstot ar masalām, ir iespēja piedzīvot vidusausu iekaisumu un pneimoniju(plaušu karsonis)?',
        answers: ['Nē, nav iespējams, kas par muļķībām.', 'Jā, ir iespējams. ', 'Nezinu, jāpajautā skolotājai.'],
        correctAnswerIndex: 1,
    },
    {
        jautId: 2,
        jautText: 'Kas ir skarlatīna?',
        answers: ['Slimība, kura bija viena no galvenajām bērnu mirstības cēloņiem.', 'Slimība, kuras simptoms ir "zilā mēle".', ' Slimība, kuru ārstē ar ārstniecības pieneņu nektāru.'],
        correctAnswerIndex: 0,
    },
	{
		jautId: 3,
		jautText: 'Ar ko ārstē skarlatīnu?',
		answers: ['Ar antibiotikām.', ' Ar Ibumetin.', 'Ar pienu.'],
		correctAnswerIndex: 0,
	},
	{
		jautId: 4,
		jautText: 'Kā cilvēks saslimst ar ērču encefalītu?',
		answers: ['Ar to saslimst, ja cilvēks aiztiek nezāles.', 'Cilvēks ar to saslimst, skūpstoties ar kādu citu.', 'Ērču encefalīts attīstās, tad kad cilvēkam ir piesūkusies ērce.'],
		correctAnswerIndex: 2,
	},
	{
		jautId: 5,
		jautText: 'Kas ir antiviela?',
		answers: ['Asins seruma olbv., kuru sintēzi ierosina kāds inf. slimību ieros. mikroorganisms, vakcīna vai cits antigēns.', 'Pārspīlēta organisma imūnreakcija.', 'Imunitāte, kas rodas, cilvēkam inficējoties.'],
		correctAnswerIndex: 0,
	},
	{
		jautId: 6,
		jautText: 'Kas ir mākslīgā imunitāte? ',
		answers: ['Cilvēka imūndeficīta sindroms.', 'Rodas, ievadot gatavas antivielas.', 'Veidojas izslimošanas rezultātā.'],
		correctAnswerIndex: 1,
	},
	{
		jautId: 7,
		jautText: 'Kas ir vakcinēšana?',
		answers: ['Nonāvētu mikroorganismu ievadīšana.', 'Pārāk spēcīga imūnsistēmas reakcija uz organismam svešām vielām.', 'Cilvēka imūndeficīta sindromu izraisošs vīruss.'],
		correctAnswerIndex: 0,
	},
	{
		jautId: 8,
		jautText: 'Kas ir autoimūna slimība?',
		answers: ['Imūnsistēmas iedarbība uz sava organisma audiem kā uz antigēniem.', 'Pārāk spēcīga imūnsistēmas reakcija uz alergēniem.', 'Rodas potēšanas rezultātā.'],
		correctAnswerIndex: 0,
	},
	{
		jautId: 9,
		jautText: 'Nosauc tuberkulozes simptomus!',
		answers: ['Ilgstošs klepus, caureja un asarainas acis.', 'Drudzis, vājums un asins vai krēpu atklepošana.', 'Sāpes krūtīs, kakla iekaisums un plīstošas zarnas.'],
		correctAnswerIndex: 1,
	},
	{
		jautId: 10,
		jautText: 'Kādi ir imunitātes veidi?',
		answers: ['Nespecifiskā jeb iedzimtā.', 'Specifiskā jeb iegūtā.', 'Visas iepriekš minētās atbildes.'],
		correctAnswerIndex: 2,
	},
	{
		jautId: 11,
		jautText: 'Kā iegūst iedzimto jeb nespecifisko imunitāti?',
		answers: ['Tā iedzimst no vecākiem.', 'Tā rodas, izslimojot slimību.', 'Tā rodas, ievadot slimniekam jau gatavas pretvielas pret slimību.'],
		correctAnswerIndex: 0,
	},
	{
		jautId: 12,
		jautText: 'Kā iegūst mākslīgo aktīvo imunitāti?',
		answers: ['Tā veidojas izslimošanas rezultātā.', 'Vakcinēšanās rezultātā.', 'Iedzimst kā sugai raksturīga pazīme.'],
		correctAnswerIndex: 1,
	},
	{
		jautId: 13,
		jautText: 'Kas ir aktīvā imunitāte?',
		answers: ['Antivielu veidošanās pret antigēniem.', 'Gatava antivielu ievadīšana.', 'Limfātiskie audi.'],
		correctAnswerIndex: 0,
	},
	{
		jautId: 14,
		jautText: 'Kas ir ārstnieciskais serums?',
		answers: ['Ilgstoša imunitāte, kuru iespējams iegūt no tējas.', 'Mākslīgās pasīvās imunitātes antiviela.', 'Antigēns.'],
		correctAnswerIndex: 1,
	},
	{
		jautId: 15,
		jautText: 'Kas ir alerģija?',
		answers: ['Rodas, ievadot gatavas antivielas.', 'Imūnsistēmas atbildes reakciju rosinoša viela.', 'Pārāk spēcīga imūnsistēmas reakcija uz alergēniem.'],
		correctAnswerIndex: 2,
	},
	{
		jautId: 16,
		jautText: 'Kā iegūst mākslīgo pasīvo imunitāti?',
		answers: ['Tā rodas pēc vakcinēšanās.', 'Ar ārstniecisko serumu - gatavām pretvielām.', 'Iedzimst kā sugai raksturīga pazīme.'],
		correctAnswerIndex: 1,
	},
	{
		jautId: 17,
		jautText: 'Kas nodrošina organisma aizsardzību pret antigēniem?',
		answers: ['Limfocīti un sarkanās kaulu smadzenes.', 'Olbaltumvielas.', 'Leikocīti un to producētās vielas.'],
		correctAnswerIndex: 2,
	},
	{
		jautId: 18,
		jautText: 'Kas ir antigēns?',
		answers: ['Jebkura viela, kas izraisa imūnsistēmas atbildi.', 'Viela, kura izraisa hemoglobīna zemo līmeni.', 'Mazasinība.'],
		correctAnswerIndex: 0,
	},
	{
		jautId: 19,
		jautText: 'Kas ir antibiotikas?',
		answers: ['Zāles, kurām ir vislielākā ietekme, īsā laika posmā, izārstēt dzīvību apdraudošas infekciju slimības.', 'Viela, kura visvairāk kļūst par iemeslu cilvēka nāvei.', 'Vīrusu izraisītājas, kuras palielina iekaisuma reakciju.'],
		correctAnswerIndex: 0,
	},
	{
		jautId: 20,
		jautText: 'Kas ir vakcīna?',
		answers: ['Narkotikas.', 'Aktīva viela, kura veido antigēnus.', 'Aizsargpote, kura veic slimību profilaksi.'],
		correctAnswerIndex: 2,
	},
	{
		jautId: 21,
		jautText: 'Kurš no apgalvojumiem ir patiess?',
		answers: ['Imunitāte ir neuzņēmība pret noteiktu infekcijas slimību ierosinātājiem.', 'Metāla gredzenu nēsāšana stiprina nēsātāja imunitāti pret jebkādām slimībām.', 'Smejoties kopumā 30 minūtes dienā, imūnsistēma var pastiprināties tiktāl, ka cilvēks kļūst pilnīgi imūns pret visām slimībām.'],
		correctAnswerIndex: 0,
	},
	{
		jautId: 22,
		jautText: 'Kāda var būt imunitāte?',
		answers: ['Iedzimta un iegūta.', 'Ārēja un iekšēja.', 'Mājas un slimnīcas.'],
		correctAnswerIndex: 0,
	},
	{
		jautId: 23,
		jautText: 'Kas ir pandēmija?',
		answers: ['Infekcijas slimības ļoti plaša izplatīšanās valstī, vairākās valstīs, kontinentos.', 'Visu pasaules cilvēku saslimšana vienlaicīgi.', 'Īpašs pannas nosaukums.'],
		correctAnswerIndex: 0,
	},
	{
		jautId: 24,
		jautText: 'Kas ir epidēmija?',
		answers: ['COVID- 19', 'Ādas virsējais slānis.', 'Infekcijas slimības plaša izplatīšanās.'],
		correctAnswerIndex: 2,
	},
    {
        jautId: 25,
        jautText: 'Kā veidojas dabiskā aktīvā imunitāte?',
        answers: ['Veidojas, ja bērns zīš mātes pienu.', 'Veidojas, ja cilvēks pārslimo kādu slimību.', 'Veidojas, ja vakcinējas.'],
        correctAnswerIndex: 1,
    },
];

export default Jautajumi;