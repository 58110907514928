import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import './css/system-messages.css';
import Cookies from 'js-cookie';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

//pages
import Noteikumi from './Noteikumi';
import NotFound from './NotFound';
import Start from './Start.js';
import Spele from './Game.js';
import DarbaSadal from './DarbaSadal';

//emojis
import us from './assets/us.png'
import { use } from 'i18next';

const App = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const navbarRef = useRef(null);
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const [showNavAndFoot, setShowNavAndFoot] = useState(true);
  const [showLogregFoot, setShowLogregFoot]= useState(false);

  const handleNavToggle = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const handleOtherButtonClick = () => {
    setIsNavCollapsed(true);
  };

  const handleAcceptCookies = () => {
    setAcceptedCookies(true);
    Cookies.set('acceptedCookies', true, {
      expires: 365,
      httpOnly: false,
      secure: true,
      sameSite: 'strict',
    });
  };

  useEffect(() => {
    const deleteExpiredCookies = () => {
      const allCookies = Cookies.get();

      Object.keys(allCookies).forEach((cookieName) => {
        const expirationDate = new Date(Cookies.get(cookieName, { raw: true }));

        if (expirationDate < new Date()) {
          Cookies.remove(cookieName, { domain: '.limro.top' });
        }
      });
    };

    deleteExpiredCookies();

    const acceptedCookies = Cookies.get('acceptedCookies');

    if (acceptedCookies === undefined || acceptedCookies === 'false') {
      setAcceptedCookies(false);
    } else {
      setAcceptedCookies(true);
    }

    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsNavCollapsed(true);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('lang', language);
  };

  useEffect(() => {
    const lang = localStorage.getItem('lang');
    let languageToSet;
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      const browserLanguage = navigator.language || navigator.userLanguage;
      const defaultLanguage = browserLanguage.substr(0, 2);

      languageToSet = ['en'].includes(defaultLanguage) ? defaultLanguage : 'en';

      localStorage.setItem('lang', languageToSet);
    }

    setShowNavAndFoot(true);
  }, [i18n]);

  return (
    <div className='custom-scrollbar-wrapper'>
      <SimpleBar className='simplebar_css' style={{ paddingRight: '0px', overflowX: 'hidden' }} autoHide={false}>
        <div>
        
        <Routes>
          <Route path="/" element={<Start showNavAndFoot={showNavAndFoot} setShowNavAndFoot={setShowNavAndFoot} showLogregFoot={showLogregFoot} setShowLogregFoot={setShowLogregFoot} />} />
          <Route path="/noteikumi" element={<Noteikumi showNavAndFoot={showNavAndFoot} setShowNavAndFoot={setShowNavAndFoot} showLogregFoot={showLogregFoot} setShowLogregFoot={setShowLogregFoot} />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/spele" element={<Spele showNavAndFoot={showNavAndFoot} setShowNavAndFoot={setShowNavAndFoot} showLogregFoot={showLogregFoot} setShowLogregFoot={setShowLogregFoot} />} />
          <Route path="/darba-sadalijums" element={<DarbaSadal showNavAndFoot={showNavAndFoot} setShowNavAndFoot={setShowNavAndFoot} showLogregFoot={showLogregFoot} setShowLogregFoot={setShowLogregFoot} />} />
        </Routes>
        
        {!acceptedCookies && (
          <div className="accept-cookies-container">
            <button className="close-cookies-button" onClick={handleAcceptCookies}>
              X
            </button>
            <div className="accept-cookies-content">
              <p>
              Mēs izmantojam sīkfailus un līdzīgas tehnoloģijas, lai šī vietne darbotos pareizi, analizētu datplūsmu un optimizētu jūsu pārlūkošanas pieredzi. Turpinot pārlūkot vietni, jūs piekrītat šādu sīkfailu izmantošanai.
              </p>
            </div>
          </div>
        )}
      </div>
    </SimpleBar>
    </div>
  );
};

export default App;