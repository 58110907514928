import React from 'react';
import KaulinaIMG from './assets/kaul_blue.png';
import "./css/game.css";

function Kaulins({ x, y }) {
    return (
        <div className="player" style={{ left: `${x}%`, top: `${y}%` }}>
            <img src={KaulinaIMG} alt="Kaulins" />
        </div>
    );
}

export default Kaulins;