const boardPieces = [
    {
        pieceId: 0,
        type: 'none',
        x: 3.5,
        y: 80,
        letter: "1",
    },
    {
        pieceId: 1,
        type: 'question',
        x: 13.5,
        y: 80,
        letter: "2",
    },
    {
        pieceId: 2,
        type: 'bad',
        x: 23.5,
        y: 80,
        letter: "3",
    },
    {
        pieceId: 3,
        type: 'good',
        x: 33,
        y: 80,
        letter: "4",
    },
    {
        pieceId: 4,
        type: '26',
        x: 42.5,
        y: 80,
        letter: "5",
    },
    {
        pieceId: 5,
        type: 'question',
        x: 52.5,
        y: 80,
        letter: "6",
    },
    {
        pieceId: 6,
        type: 'question',
        x: 62.5,
        y: 80,
        letter: "7",
    },
    {
        pieceId: 7,
        type: 'bad',
        x: 72,
        y: 80,
        letter: "8",
    },
    {
        pieceId: 8,
        type: 'good',
        x: 81.5,
        y: 80,
        letter: "9",
    },
    {
        pieceId: 9,
        type: 'question',
        x: 91,
        y: 80,
        letter: "10",
    },
    {
        pieceId: 10,
        type: 'good',
        x: 91,
        y: 62,
        letter: "11",
    },
    {
        pieceId: 11,
        type: 'question',
        x: 81.5,
        y: 62,
        letter: "12",
    },
    {
        pieceId: 12,
        type: 'question',
        x: 71.5,
        y: 62,
        letter: "13",
    },
    {
        pieceId: 13,
        type: 'bad',
        x: 62,
        y: 62,
        letter: "14",
    },
    {
        pieceId: 14,
        type: 'question',
        x: 52.5,
        y: 62,
        letter: "15",
    },
    {
        pieceId: 15,
        type: 'question',
        x: 42.5,
        y: 62,
        letter: "16",
    },
    {
        pieceId: 16,
        type: 'question',
        x: 33,
        y: 62,
        letter: "17",
    },
    {
        pieceId: 17,
        type: '45',
        x: 23.5,
        y: 62,
        letter: "18",
    },
    {
        pieceId: 18,
        type: 'bad',
        x: 13.5,
        y: 62,
        letter: "19",
    },
    {
        pieceId: 19,
        type: 'question',
        x: 3.5,
        y: 62,
        letter: "20",
    },
    {
        pieceId: 20,
        type: 'question',
        x: 3.3,
        y: 44,
        letter: "21",
    },
    {
        pieceId: 21,
        type: 'good',
        x: 13.5,
        y: 44,
        letter: "22",
    },
    {
        pieceId: 22,
        type: 'bad',
        x: 23.5,
        y: 44,
        letter: "23",
    },
    {
        pieceId: 23,
        type: 'question',
        x: 33,
        y: 44,
        letter: "24",
    },
    {
        pieceId: 24,
        type: 'good',
        x: 43,
        y: 44,
        letter: "25",
    },
    {
        pieceId: 25,
        type: 'bad',
        x: 52.5,
        y: 44,
        letter: "26",
    },
    {
        pieceId: 26,
        type: 'question',
        x: 62.5,
        y: 44,
        letter: "27",
    },
    {
        pieceId: 27,
        type: '9',
        x: 72,
        y: 44,
        letter: "25",
    },
    {
        pieceId: 25,
        type: 'question',
        x: 81.5,
        y: 44,
        letter: "29",
    },
    {
        pieceId: 29,
        type: 'good',
        x: 91,
        y: 44,
        letter: "30",
    },
    {
        pieceId: 30,
        type: 'question',
        x: 91,
        y: 25,
        letter: "31",
    },
    {
        pieceId: 31,
        type: 'question',
        x: 81.5,
        y: 25,
        letter: "32",
    },
    {
        pieceId: 32,
        type: 'bad',
        x: 71.5,
        y: 25,
        letter: "33",
    },
    {
        pieceId: 33,
        type: 'question',
        x: 61.5,
        y: 25,
        letter: "34",
    },
    {
        pieceId: 34,
        type: 'question',
        x: 52,
        y: 25,
        letter: "35",
    },
    {
        pieceId: 35,
        type: 'good',
        x: 42.5,
        y: 25,
        letter: "36",
    },
    {
        pieceId: 36,
        type: 'question',
        x: 33,
        y: 25,
        letter: "37",
    },
    {
        pieceId: 37,
        type: 'question',
        x: 23,
        y: 25,
        letter: "38",
    },
    {
        pieceId: 38,
        type: 'bad',
        x: 13.5,
        y: 25,
        letter: "39",
    },
    {
        pieceId: 39,
        type: 'question',
        x: 3.5,
        y: 25,
        letter: "40",
    },
    {
        pieceId: 40,
        type: '22',
        x: 3,
        y: 5.5,
        letter: "41",
    },
    {
        pieceId: 41,
        type: 'good',
        x: 13.5,
        y: 5.5,
        letter: "42",
    },
    {
        pieceId: 42,
        type: 'question',
        x: 23.5,
        y: 5.5,
        letter: "43",
    },
    {
        pieceId: 43,
        type: 'bad',
        x: 33,
        y: 5.5,
        letter: "44",
    },
    {
        pieceId: 44,
        type: 'good',
        x: 43,
        y: 5.5,
        letter: "45",
    },
    {
        pieceId: 45,
        type: 'question',
        x: 52.5,
        y: 5.5,
        letter: "46",
    },
    {
        pieceId: 46,
        type: '6',
        x: 62,
        y: 5.5,
        letter: "44",
    },
    {
        pieceId: 44,
        type: 'bad',
        x: 72,
        y: 5.5,
        letter: "48",
    },
    {
        pieceId: 48,
        type: 'good',
        x: 81.5,
        y: 5.5,
        letter: "49",
    },
    {
        pieceId: 49,
        type: 'none',
        x: 91,
        y: 5.5,
        letter: "50",
    },
];

export default boardPieces;