import React from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.min.css';
import './App.css';
import './css/features.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Noteikumi = ({ showNavAndFoot, setShowNavAndFoot, showLogregFoot, setShowLogregFoot }) => {
  const { t } = useTranslation();
  setShowNavAndFoot(false);
  setShowLogregFoot(false);

    return (
      <div>
        <Helmet>
          <title>Noteikumi | BIO Radošais Projekts</title>
        </Helmet>

        <div className='heading3 ppolicy_tos_container'>
          <h1 className='display-5 title'>Spēles noteikumi</h1>
          <p className='title5'>1. Neizmantot pārlūkprogrammas iebūvētos izstrādātāja rīkus.</p>
          <p className='title5'>2. Neizmantot pārlūkprogrammas papildinājumus, kas var kaut kādā veidā negatīvi ietekmēt spēli un tās funkcijas.</p>
          <p className='title5'>3. Spēles laikā ieteicams neizmantot mācību grāmatas un internetu, lai rezultāti būtu godīgi un spēle būtu interesantāka. (Tāpat katras nepareizas izvēles beigās jums pateiks pareizo atbildi.)</p>
          <p className='title5'>4. Spēlēt godprātīgi, izlasot visus tekstus, kuri ir dialogos, lai vismaz kaut kas paliktu prātā.</p>
          <br/>
          <h1 className='display-5 title'>Informācija par lauciņiem</h1>
          <p className='title5'>Jautājuma lauciņš - tiks uzdots jautājums un jāizvēlas pareizā atbilde no 3 iespējamiem variantiem, pareizā atbilde piešķir punktus!</p>
          <p className='title5'>Slimības lauciņš - izlaid gājienu, kamēr uzzini jaunu faktu par iegūto slimību, atkarībā no slimības smaguma tiks atņemti punkti!</p>
          <p className='title5'>Veiksmes lauciņš - izlaid gājienu, kamēr iegūsti punktus, izbaudi dzīvi!</p>
          <p className='title5'>Bonusa lauciņš - atkarībā no kāpnēm un bultiņām virzies augstāk vai zemāk uz laukuma!</p>
        </div>
      </div>
    );
  };
  
  export default Noteikumi;