import React, { useState, useEffect } from 'react';

const WelcomeAnimation = () => {
    const translations = [
      { language: 'English', translation: 'Masalas' },
      { language: 'French', translation: 'Skarlatīna' },
      { language: 'Spanish', translation: 'Encefalīts' },
      { language: 'German', translation: 'Dizentērija' },
      { language: 'Japanese', translation: 'Gonoreja' },
      { language: 'Arabic', translation: 'Botulisms' },
      { language: 'Russian', translation: 'Masaliņas' },
      { language: 'Chinese (Simplified)', translation: 'Tuberkuloze' },
      { language: 'Korean', translation: 'Gripa' },
      { language: 'Italian', translation: 'Pneimonija' },
      { language: 'Portuguese', translation: 'Cūciņa (parotīts)' },
      { language: 'Dutch', translation: 'Diftērija' },
      { language: 'Swedish', translation: 'Salmoneloze' },
      { language: 'Polish', translation: 'Vējbakas' },
      { language: 'Turkish', translation: 'Stingumkrampji' },
      { language: 'Vietnamese', translation: 'Trakumsērga' },
      { language: 'Thai', translation: 'Kārpas' },
      { language: 'Finnish', translation: 'Garais klepus' }
    ];

    const [currentTranslationIndex, setCurrentTranslationIndex] = useState(0);
    const [typedText, setTypedText] = useState('');
    const [typingDirection, setTypingDirection] = useState('forward');
    const [typingSpeed, setTypingSpeed] = useState(150);
    const [wordWritten, setWordWritten] = useState(false);
    const [wordTyped, setWordTyped] = useState(false);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        if (typingDirection === 'forward') {
          if (typedText.length < translations[currentTranslationIndex].translation.length) {
            setTypedText(prevTypedText => prevTypedText + translations[currentTranslationIndex].translation.charAt(prevTypedText.length));
          } else {
            setTypingDirection('backward');
            setTypingSpeed(60); //Speed up backspacing
            setWordTyped(true);
          }
        } else {
          if (typedText.length > 0) {
            setTypedText(prevTypedText => prevTypedText.slice(0, -1));
          } else {
            setTypingDirection('forward');
            setTypingSpeed(150); //Reset typing speed
            setCurrentTranslationIndex(prevIndex => (prevIndex + 1) % translations.length);
            setWordTyped(false);
          }
        }
      }, typingSpeed);
  
      return () => clearInterval(intervalId);
    }, [typingDirection, typingSpeed, typedText, translations, currentTranslationIndex]);
  
    useEffect(() => {
      if (wordTyped) {
        const timeoutId = setTimeout(() => {
          setWordWritten(true);
        }, 3000);
  
        return () => clearTimeout(timeoutId);
      }
    }, [wordTyped]);
  
    useEffect(() => {
      if (wordWritten) {
        setTypedText('');
        setWordWritten(false);
      }
    }, [wordWritten]);
  
    return <h1 className="display-5 title">| {typedText} |</h1>;
  };
  
  export default WelcomeAnimation;