import React from 'react';

function Piece({ x, y, letter }) {
    return (
        <div style={{
            position: 'absolute',
            width: '5%',
            height: '5%',
            borderRadius: '0px',
            left: `${x}%`,
            top: `${y}%`,
        }}>
            <p style={{ fontSize: '2.25vw', fontWeight: 'bold', color: '#00000000', textAlign: 'center', textShadow: '#00000000 0.27vw 0.27vw' }}>{letter}</p>
        </div>
    );
}

export default Piece;