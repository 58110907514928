import React, { useState, useEffect } from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.min.css';
import './App.css';
import './css/features.css'
import './css/leaderboard.css'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import WelcomeAnimation from './WelcomeAnimation';

const Start = ({ showNavAndFoot, setShowNavAndFoot, showLogregFoot, setShowLogregFoot }) => {
    const { t } = useTranslation();

    const [userData, setUserData] = useState([]);

    setShowNavAndFoot(false);
    setShowLogregFoot(false);

    const navigateToGame = () => {
        window.location.href = '/spele';
    };
    const navigateToNoteikumi = () => {
        window.location.href = '/noteikumi';
    };
    const navigateToDarbaSadal = () => {
        window.location.href = '/darba-sadalijums';
    };

    const getUsersData = () => {
        try {
            const userDataFromLocalStorage = JSON.parse(localStorage.getItem('userData'));
            if (Array.isArray(userDataFromLocalStorage)) {
                userDataFromLocalStorage.sort((a, b) => b.punktuSk - a.punktuSk);
    
                const userDataWithPlace = userDataFromLocalStorage.map((user, index) => ({
                    ...user,
                    place: index + 1,
                }));
    
                setUserData(userDataWithPlace);
            } else {
                console.error('User data is not an array.');
            }
        } catch (error) {
            console.error('Error parsing user data from local storage:', error);
        }
    }

    useEffect(() => {
        getUsersData();
    }, []);

    return (
        <div className='page-header'>
        <div className="container">
        <div className="row">
        <div className="col-md-9 col-md-offset-2 cont_start">
                <Helmet>
                    <title>Spēle "Imunitāte" | BIO Radošais Projekts</title>
                </Helmet>
                <div className="heading3">
                    <WelcomeAnimation />
                    <p className="title4">Galda spēle "Imunitāte"</p>
                    <p className='title4'>Autori - Mariuss, Sāra, Elīna, 11.c</p>
                    <div className='btncontainer'>
                        <a className="btn btn-primary btn-lg" onClick={navigateToGame} target="_blank" rel="noopener noreferrer" role="button">Sākt spēli</a>
                        <a className="btn btn-secondary btn-lg" onClick={navigateToNoteikumi} target="_blank" rel="noopener noreferrer" role="button">Noteikumi</a>
                        <a className="btn btn-secondary btn-lg" onClick={navigateToDarbaSadal} target="_blank" rel="noopener noreferrer" role="button">Darba sadalījums</a>
                    </div>
                    <div className="coin-leaderboard">
                        {userData.length > 0 ? (
                            <>
                                <h1 className="display-5 title3">Galda spēles "Imunitāte" lokālais tops:</h1>
                                <br/>
                                <table className="leaderboard-table">
                                    <thead>
                                        <tr>
                                            <th>Vieta</th>
                                            <th>Psdeidonīms</th>
                                            <th>Punktu skaits</th>
                                            <th>Datums</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userData.map((user, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {user.place === 1 ? '🥇 1.' : user.place === 2 ? '🥈 2.' : user.place === 3 ? '🥉 3.' : `${user.place}.`}
                                                </td>
                                                <td>{user.username}</td>
                                                <td>{user.punktuSk}</td>
                                                <td>{user.played}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <h1 className='title4'>Šeit būs lokālais tops. Izspēlējiet vienu maču =)</h1>
                        )}
                </div>
                </div>
        </div>
        </div>
        </div>
        </div>
    );
};

export default Start;