import React, {useState, useEffect, useRef} from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.min.css';
import './App.css';
import './css/game.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { MapInteractionCSS, MapInteraction } from 'react-map-interaction';
import Piece from "./Piece";
import Kaulins from "./Kaulins";
import GaldaIMG from './assets/LimroBot.png';
import boardPieces from './PiecesSet';
import { random, times } from 'lodash';
import Jautajumi from './Jautajumi';
import { use } from 'i18next';
import { set } from 'lodash';
import Slimibas from './Slimibas';
import Bonusi from './Bonusi';

//kaulini metamie
import one from './assets/kaul_one.png';
import two from './assets/kaul_two.png';
import three from './assets/kaul_three.png';
import four from './assets/kaul_four.png';
import five from './assets/kaul_five.png';
import six from './assets/kaul_six.png';

import galds from './assets/IMG_8308.png';

const Game = ({ showNavAndFoot, setShowNavAndFoot, showLogregFoot, setShowLogregFoot }) => {
    const { t } = useTranslation();
    
    const [username, setUsername] = useState('');
    const [punktuSk, setPunktuSk] = useState(0);
    const [punktuMultiplier, setPunktuMultiplier] = useState(1);
    
    const [isDied, setIsDied] = useState(false);
    const [kaulinaNr, setKaulinaNr] = useState(0);
    const [lastGajiens, setLastGajiens] = useState(null);

    const [translationX, setTranslationX] = useState(0);

    const [mapState, setMapState] = useState({
        scale: 0.9,
        translation: {x: 0, y: 0},
    });

    const [showModal, setShowModal] = useState(false);
    const [modalTitleText, setModalTitleText] = useState('');
    const [modalSubtitleText, setModalSubTitleText] = useState('');
    const [modalBtnText, setModalBtnText] = useState('');
    const [showCorrectAnsInModal, setShowCorrectAnsInModal] = useState('');
    const [onModalClickBtn, setOnModalClickBtn] = useState('');

    const displayModal = ({ titleText, subtitleText, mainBtnText, onModalClick }) => {
        setModalTitleText(titleText);
        setModalSubTitleText(subtitleText);
        setModalBtnText(mainBtnText);
        setOnModalClickBtn(onModalClick);
        console.log(onModalClick, onModalClickBtn);
        handleOpenModal();
    };

    const modalClickHandler = () => {
        if(onModalClickBtn === 'close'){
            handleCloseModal();
        } else if(onModalClickBtn === 'fakts'){
            openRandomFact();
        } else if(onModalClickBtn === 'gameover'){
            handleModalGameover();
        } else {
            console.log('Click handler unknown onClick type');
        }
    }

    const handleOpenModal = () => {
        setShowModal(true);
        console.log('display modal true');
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setShowCorrectAnsInModal(false);
        console.log('display modal false');
    }

    const handleModalGameover = () => {
        setShowModal(false);
        setShowCorrectAnsInModal(false);
        handleOpenInputModal();
        console.log('triggering gameover screen');
    }

    const [showUserInputModal, setShowUserInputModal] = useState(null);

    const handleOpenInputModal = () => {
        setShowUserInputModal(true);
    }

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    const handleCloseInputModal = () => {
        setShowUserInputModal(false);
        const existingUserData = JSON.parse(localStorage.getItem('userData')) || [];
        const timestamp = new Date().getTime();
        const played = formatDate(timestamp);
        const currentUserData = { username, punktuSk, played };
        existingUserData.push(currentUserData);

        localStorage.setItem('userData', JSON.stringify(existingUserData));

        navigateToBack();
    }

    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [qModalTitleText, setQModalTitleText] = useState('');
    const [qModalSubtitleText, setQModalSubtitleText] = useState('');
    const [qAnsBtn1Text, setQAnsBtn1Text] = useState('');
    const [qAnsBtn2Text, setQAnsBtn2Text] = useState('');
    const [qAnsBtn3Text, setQAnsBtn3Text] = useState('');
    const [qCorrectAnswer, setQCorrectAnswer] = useState('');

    const handleOpenQModal = () => {
        setShowQuestionModal(true);
    }

    const handleCloseQModal = () => {
        setShowQuestionModal(false);
    }

    const displayQuestionModal = (qModalTitle) => {
        setQModalTitleText(qModalTitle);
    
        const randomQuestion = Jautajumi[Math.floor(Math.random() * Jautajumi.length)];
        setQModalSubtitleText(randomQuestion.jautText);
    
        const correctAnswer = randomQuestion.answers[randomQuestion.correctAnswerIndex];
        setQCorrectAnswer(correctAnswer);
    
        const answers = Jautajumi.flatMap(q => q.answers);
        const randomAnswers = [correctAnswer];
    
        const shuffledAnswers = randomQuestion.answers.slice().sort(() => Math.random() - 0.5);

        setQAnsBtn1Text(shuffledAnswers[0]);
        setQAnsBtn2Text(shuffledAnswers[1]);
        setQAnsBtn3Text(shuffledAnswers[2]);
    
        handleOpenQModal();
    }

    const checkQuestionAnswerInput = (index) => {
        handleCloseQModal();

        let selectedAnswer;
        if(index === 1) selectedAnswer = qAnsBtn1Text;
        else if(index === 2) selectedAnswer = qAnsBtn2Text;
        else if(index === 3) selectedAnswer = qAnsBtn3Text;

        if(selectedAnswer === qCorrectAnswer){
            const randomPunkti = generateRandomPointsGoodQ();
            console.log(`Pareizā atbilde! + ${randomPunkti} punkti`)
            setPunktuSk(punktuSk + randomPunkti);
            displayModal({
                titleText: '✅ Pareizā atbilde!',
                subtitleText: `Labs darbs! Jūs izvēlējāties pareizo atbildi! +${randomPunkti} punkts/-i!`,
                mainBtnText: 'Urā',
                onModalClick: 'close',
            })
        } else {
            const randomPunkti = generateRandomPointsBadQ();
            setShowCorrectAnsInModal(true);
            console.log(`Nepareizā atbilde =(! Pareizā atbilde: ${qCorrectAnswer}! -${randomPunkti} punkts/-i`)
            setPunktuSk(punktuSk - randomPunkti);
            displayModal({
                titleText: '❌ Nepareizā atbilde!',
                subtitleText: `Diemžēl jūs izvēlējāties nepareizo atbildi! -${randomPunkti} punkts/-i!`,
                mainBtnText: 'Labi =(',
                onModalClick: 'close',
            })
        }
    }

    const generateRandomPointsGoodQ = () => {
        const min = 100;
        const max = 200;
        const randPoints = Math.floor(Math.random() * (max-min+1)) +min;
        const galaRezult = Math.round(randPoints * punktuMultiplier);
        return galaRezult;
    }

    const generateRandomPointsBadQ = () => {
        const min = 100;
        const max = 200;
        const randPoints = Math.floor(Math.random() * (max-min+1)) +min;
        const galaRezult = Math.round(randPoints * punktuMultiplier);
        return galaRezult;
    }

    const [kaulinsCoords, setKaulinsCoords] = useState({ x: 2.5, y: 81 })

    const renderBoardPieces = () => {
        return boardPieces.map((boardPiece, index) => (
            <Piece key={`board-piece-${index}`} x={boardPiece.x} y={boardPiece.y} letter={boardPiece.letter} />
        ))
    };

    const renderKaulinsPlayer = () => {
        return <Kaulins key={`kaulins`} x={kaulinsCoords.x} y={kaulinsCoords.y} />
    }

    const [fakts, setFakts] = useState('');
    const [slimibasSmagums, setslimibasSmagums] = useState(0);

    const generateRandomSlimiba = () => {
        const randomSlimiba = Slimibas[Math.floor(Math.random() * Slimibas.length)];
        const randFaktaId = Math.floor(Math.random() * 3);
        const fakts = randomSlimiba[`slimibasFakts${randFaktaId + 1}`];

        console.log(randomSlimiba.slimibasNosauk);
        console.log(fakts)
        console.log(randomSlimiba.slimibasSmagums);
        
        setFakts(fakts);
        showSlimibasResult(randomSlimiba);
    }

    const showSlimibasResult = (randomSlimiba) => {
        let randomPunkti;

        if(randomSlimiba.slimibasSmagums === 1){
            randomPunkti = generateRandomPointsBad1();
            console.log(`sliktais laucins - ${randomPunkti} punkti`);
            setPunktuSk(punktuSk - randomPunkti);
            displayModal({
                titleText: '⚠️ Slimības lauciņš =(',
                subtitleText: `Jūs saslimāt ar ${randomSlimiba.slimibasNosauk}!
                Slimība nav tik traka, tāpēc lai izveseļotos, mēs jums atņēmām ${randomPunkti} punktu/-us!`,
                mainBtnText: 'Bēdīgi',
                onModalClick: 'fakts',
            });
        } else if(randomSlimiba.slimibasSmagums === 2){
            randomPunkti = generateRandomPointsBad2();
            console.log(`sliktais laucins - ${randomPunkti} punkti`);
            setPunktuSk(punktuSk - randomPunkti);
            displayModal({
                titleText: '⚠️ Slimības lauciņš =(',
                subtitleText: `Jūs saslimāt ar ${randomSlimiba.slimibasNosauk}!
                Slimība ir traka, tāpēc lai izveseļotos, mēs jums atņēmām ${randomPunkti} punktu/-us!`,
                mainBtnText: 'Bēdīgi',
                onModalClick: 'fakts',
            });
        } else if(randomSlimiba.slimibasSmagums === 3){
            setIsDied(true);
            displayModal({
                titleText: '⚠️ Slimības lauciņš =(',
                subtitleText: `Jūs saslimāt ar ${randomSlimiba.slimibasNosauk}! Slimība ļoti traka un jūs nomirāt!`,
                mainBtnText: 'Bēdīgi',
                onModalClick: 'fakts',
            });
        } else {
            console.log("Slimibas smagums not set on server!");
            console.log(randomSlimiba.slimibasSmagums);
            displayModal({
                titleText: 'Error',
                subtitleText: `variable slimibasSmagumsums has not been set on server!`,
                mainBtnText: 'Bēdīgi',
                onModalClick: 'close',
            });
        }
    }

    const openRandomFact = () => {
        if(isDied){
            displayModal({
                titleText: 'ℹ️ Interesants fakts par šo slimību',
                subtitleText: `${fakts}.`,
                mainBtnText: 'Labi',
                onModalClick: 'gameover',
            });
        } else {
            displayModal({
                titleText: 'ℹ️ Interesants fakts par šo slimību',
                subtitleText: `${fakts}.`,
                mainBtnText: 'Labi',
                onModalClick: 'close',
            });
        }
        setBadLaucins(false);
    }

    const generateRandomPointsBad1 = () => {
        const min = 100;
        const max = 150;
        const randPoints = Math.floor(Math.random() * (max-min+1)) +min;
        const galaRezult = Math.round(randPoints * punktuMultiplier);
        return galaRezult;
    }

    const generateRandomPointsBad2 = () => {
        const min = 125;
        const max = 200;
        const randPoints = Math.floor(Math.random() * (max-min+1)) +min;
        const galaRezult = Math.round(randPoints * punktuMultiplier);
        return galaRezult;
    }

    const mapContainerRef = useRef(null);
    const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateDimensions = () => {
            if (mapContainerRef.current) {
                setContainerDimensions({
                    width: mapContainerRef.current.offsetWidth,
                    height: mapContainerRef.current.offsetHeight,
                });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const handleMapChange = (value) => {
        if (value.scale < 0.9) {
            value.scale = 0.9;
        }

        setMapState(value);
    };

    const mestKaulinu = () => {
        const randomNr = Math.floor(Math.random() * 6)+1;
        setKaulinaNr(randomNr);
        if(lastGajiens == null){
            setLastGajiens(randomNr+1);
        } else {
            setLastGajiens(prevLastGajiens => prevLastGajiens + randomNr);
        }
        console.log(lastGajiens);
    };

    useEffect(() => {
        if (lastGajiens > 50) {
            const difference = lastGajiens - 50;
            setLastGajiens(50 - difference); 
            moveKaulins();
        } else if (lastGajiens === 50) {
            console.log('Tu uzvareji!');
            handleOpenInputModal();
        } else {
            moveKaulins();
        }
    }, [lastGajiens]);

    const moveKaulins = () => {
        if(lastGajiens<=1){
            setKaulinsCoords({ x: 2.5, y: 81 });
        } else {
            const piece = boardPieces.find(piece => piece.pieceId === lastGajiens-1);
            if(piece){
                const newX = piece.x;
                const newY = piece.y;
                setKaulinsCoords({ x: newX, y: newY });
                if(!isBonus){
                    checkUzd(piece);
                } else {
                    setIsBonus(false);
                }
            }
        }
    }

    const generateRandomPointsGood = () => {
        const min = 50;
        const max = 100;
        const randPoints = Math.floor(Math.random() * (max-min+1)) +min;
        const galaRezult = Math.round(randPoints * punktuMultiplier);
        return galaRezult;
    }

    const [badLaucins, setBadLaucins] = useState(false);

    const checkUzd = (piece) => {
        if(piece.type === 'none'){
            console.log('aaiaiai rawr paveicās // diplsay dialog');
            displayModal({
                titleText: '⬛ Tukšais lauciņš',
                subtitleText: 'Iespējams, ka jums ļoti paveicās, un jūs trāpījāt tukšajā lauciņā. Nekas netika pievienots vai noņemts.',
                mainBtnText: 'Oki',
                onModalClick: 'close',
            });
        }
        else if(piece.type === 'good'){
            const randomPunkti = generateRandomPointsGood();
            console.log(`labais laucins + ${randomPunkti} punkti`);
            setPunktuSk(punktuSk + randomPunkti);
            displayModal({
                titleText: '❇️ Veiksmīgais lauciņš :)',
                subtitleText: `Jūs pa ceļam sastapāt slimību, bet jūsu imunitāte veiksmīgi uzvarēja to! Pievienojām jums ${randomPunkti} punktu/-us!`,
                mainBtnText: 'Urā',
                onModalClick: 'close',
            });
        }
        else if(piece.type === 'bad'){
            setBadLaucins(true);
            generateRandomSlimiba();
        }
        else if (piece.type === "question"){
            console.log('question laucins, start dialog');
            displayQuestionModal('❓ Jautājuma lauciņš')
        }
        else if (piece.type === "bonus"){
            console.log('bonuss laucins, give random bonuss');
            generateRandomBonus();
        }
        else if (piece.type === '26') {
            setLastGajiens(26);
            setKaulinsCoords({ x: 52.5, y: 43 });
        } else if (piece.type === '45') {
            setLastGajiens(45);
            setKaulinsCoords({ x: 43, y: 5.5 });
        } else if (piece.type === '9'){
            setLastGajiens(9);
            setKaulinsCoords({ x: 81.5, y: 80 });
        } else if (piece.type === '22') {
            setLastGajiens(22);
            setKaulinsCoords({ x: 13.5, y: 43 });
        } else if (piece.type === '6') {
            setLastGajiens(6);
            setKaulinsCoords({ x: 62.5, y: 80 });
        }
        else {
            console.log('Invalid type of laucins. Ignoring.')
            displayModal({
                titleText: '❌ Nezināms lauciņš',
                subtitleText: 'Lauciņa tips nav definēts un tā ir kļūda, kuru vajag nodot devam.',
                mainBtnText: 'Oki',
                onModalClick: 'close',
            });
        }
    }

    const navigateToBack = () => {
        window.location.href = '/';
    };

    setShowNavAndFoot(false);
    setShowLogregFoot(false);

    const integrateKaulPicture = () => {
        if(kaulinaNr === 0){
            return (
                <div>
                    <img src={one} className='met_kaul'/>
                </div>
            );
        } else if(kaulinaNr === 1){
            return (
                <div>
                    <img src={one} className='met_kaul'/>
                </div>
            );
        } else if(kaulinaNr === 2){
            return (
                <div>
                    <img src={two} className='met_kaul'/>
                </div>
            );
        } else if(kaulinaNr === 3){
            return (
                <div>
                    <img src={three} className='met_kaul'/>
                </div>
            );
        } else if(kaulinaNr === 4){
            return (
                <div>
                    <img src={four} className='met_kaul'/>
                </div>
            );
        } else if(kaulinaNr === 5){
            return (
                <div>
                    <img src={five} className='met_kaul'/>
                </div>
            );
        } else if(kaulinaNr === 6){
            return (
                <div>
                    <img src={six} className='met_kaul'/>
                </div>
            );
        } else {
            return(
                <div>

                </div>
            );
        }
    }

    const showLastGajiens = () => {
        if(lastGajiens === null){
            return 1;
        } else {
            return lastGajiens;
        }
    }

    const [bonusuList, setBonusuList] = useState([]);
    const [showBonusaModal, setShowBonusaModal] = useState(false);
    const [isBonus, setIsBonus] = useState(false);

    const displayBonusiDialog = () => {
        setShowBonusaModal(true);
    };

    const generateRandomBonus = () => {
        setIsBonus(true);
        const randomBonus = Bonusi[Math.floor(Math.random() * Bonusi.length)];
        setBonusuList([...bonusuList, randomBonus.bonusaId]);
        if (randomBonus.bonusaId === 0) {
            setPunktuMultiplier(1.2);
        } else if (randomBonus.bonusaId === 1) {
            setLastGajiens(lastGajiens - 5);
            if(lastGajiens<=0){
                setLastGajiens(0);
            }
            moveKaulins();
        } else if (randomBonus.bonusaId === 2) {
            setLastGajiens(lastGajiens - 10);
            if(lastGajiens<=0){
                setLastGajiens(0);
            }
            moveKaulins();
        }
        displayModal({
            titleText: '🎁 Bonusa lauciņš',
            subtitleText: `Jūs dabūjāt "${randomBonus.bonusaApraksts}" bonusu!`,
            mainBtnText: 'Urā',
            onModalClick: 'close',
        });
    };

    const renderBonuses = () => {
        let activeBonuses;
        if (bonusuList.length === 0) {
            activeBonuses = [];
        } else {
            activeBonuses = Bonusi.filter(b => bonusuList.includes(b.bonusaId));
        }

        return (
            <div>
                {showBonusaModal && (
                    <div>
                        <div className="modal-backdrop fade show"></div>

                        <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Jūsu bonusu vēsture:</h5>
                                    </div>
                                    <div className="modal-body">
                                        {activeBonuses.length === 0 ? (
                                            <p>Nebija neviena bonusa... =(</p>
                                        ) : (
                                            <ul>
                                                {activeBonuses.map((bonus, index) => (
                                                    <li key={index}>{bonus.bonusaId} - {bonus.bonusaApraksts}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={handleCloseBonusDialog}>Labi</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const handleCloseBonusDialog = () => {
        setShowBonusaModal(false);
    };

    return (
        <div>
                <Helmet>
                    <title>Spēle "Imunitāte" | BIO Radošais Projekts</title>
                </Helmet>

                {renderBonuses()}

                {showModal && (
                    <div>
                        <div className="modal-backdrop fade show"></div>

                        <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{modalTitleText}</h5>
                                    </div>
                                    <div className="modal-body">
                                        <p>{modalSubtitleText}</p>
                                        {showCorrectAnsInModal && (
                                            <p>Pareizā atbilde bija: <i><b>{qCorrectAnswer}</b></i></p>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={modalClickHandler}>{modalBtnText}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showQuestionModal && (
                    <div>
                        <div className="modal-backdrop fade show"></div>

                        <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }} tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{qModalTitleText}</h5>
                                    </div>
                                    <div className="modal-body">
                                        <p>{qModalSubtitleText}</p>
                                    </div>
                                    <div className="modal-body buttons-row">
                                        <button type="button" className="btn5 btn-primary2" onClick={() => checkQuestionAnswerInput(1)}>{qAnsBtn1Text}</button>
                                        <button type="button" className="btn5 btn-primary2" onClick={() => checkQuestionAnswerInput(2)}>{qAnsBtn2Text}</button>
                                        <button type="button" className="btn5 btn-primary2" onClick={() => checkQuestionAnswerInput(3)}>{qAnsBtn3Text}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showUserInputModal && (
                    <div>
                        <div className="modal-backdrop fade show"></div>

                        <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Spēles beigas!</h5>
                                    </div>
                                    <div className="modal-body">
                                        <p>Lūgums ievadīt jūsu psedonīmu, lai vēlāk varētu salīdzināt rezultātus! =)</p>
                                        <label for="usernameInput">Psdeidonīms</label>
                                        <input type="username" class="form-control" id="usernameInput" aria-describedby="usernameHelp" placeholder="Ievadiet savu pseidonīmu" onChange={e => setUsername(e.target.value)}/>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={handleCloseInputModal}>Labi</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='game'>
                    <div className="heading3">
                        <MapInteractionCSS
                            value={mapState}
                            onChange={handleMapChange}
                        >
                            <div style={{ position: 'relative' }}>
                                <img className='galdaIMG' src={galds} alt="Game Board" />
                                {renderBoardPieces()}
                                {renderKaulinsPlayer()}
                            </div>
                        </MapInteractionCSS>
                    </div>
                </div>
                <div className='game_info_container'>
                        <h1 className='title4'>Punktu skaits: {punktuSk} | </h1>
                        <h1 className='title4'>Metamā kauliņa skaitlis: {kaulinaNr} | </h1>
                        <h1 className='title4'>Tava pozīcija: {showLastGajiens()} | </h1>
                        {integrateKaulPicture()}
                        <div className='btncontainer'>
                            <a className="btn btn-primary btn-lg" onClick={mestKaulinu} rel="noopener noreferrer" role="button">Mest kauliņu</a>
                            {/*<a className="btn btn-secondary btn-lg" onClick={displayBonusiDialog} rel="noopener noreferrer" role="button">Bonusu vēsture</a>*/}
                        </div>
                </div>
        </div>
    );
};

export default Game;
