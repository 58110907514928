const Bonusi = [
    {
        bonusaId: 0,
        bonusaApraksts: '1.2x punkti labajiem un jautājuma lauciņiem līdz spēles beigām',
    },
    {
        bonusaId: 1,
        bonusaApraksts: 'Atmest atpakaļ uz 5 lauciņiem',
    },
    {
        bonusaId: 2,
        bonusaApraksts: 'Atmest atpakaļ uz 10 lauciņiem',
    },
];

export default Bonusi;