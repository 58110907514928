import React from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.min.css';
import './App.css';
import './css/features.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const DarbaSadal = ({ showNavAndFoot, setShowNavAndFoot, showLogregFoot, setShowLogregFoot }) => {
  const { t } = useTranslation();
  setShowNavAndFoot(false);
  setShowLogregFoot(false);

    return (
      <div>
        <Helmet>
          <title>Noteikumi | BIO Radošais Projekts</title>
        </Helmet>

        <div className='heading3 ppolicy_tos_container'>
          <h1 className='display-5 title'>Darba sadalījums</h1>
          <br/>
          <p className='title5'>Mariuss - 100% frontend (mājaslapa), spēles loģika (programmēšanas ziņā), testēšana 100%</p>
          <p className='title5'>Elīna - 40% dizains, 60% teorija</p>
          <p className='title5'>Sāra - 30% teorija, 70% dizains</p>
        </div>
      </div>
    );
  };
  
  export default DarbaSadal;