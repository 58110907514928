const Slimibas = [
    {
        slimibasId: 0,
        slimibasNosauk: 'Masalām',
        slimibasSmagums: 1,
        slimibasFakts1: 'Masalas ir sena slimība, kuras apraksti datēti vismaz ar 9. gadsimtu, kad persiešu ārsts Rhazes aprakstīja slimību. Tā ir viena viena no vislipīgākajām slimībām.',
        slimibasFakts2: 'Aptuveni 30% cilvēku, kuri saslimst ar masalām, piedzīvo komplikācijas, piemēram, vidusauss iekaisumu, pneimoniju un encefalītu.',
        slimibasFakts3: 'Pēc atveseļošanās no masalām cilvēki parasti iegūst mūža imunitāti pret šo slimību.',
    },
    {
        slimibasId: 1,
        slimibasNosauk: 'Skarlatīnu',
        slimibasSmagums: 1,
        slimibasFakts1: 'Pirms antibiotiku ieviešanas tā bija viena no galvenajām bērnu mirstības cēloņiem.',
        slimibasFakts2: 'Viens no skarlatīnas simptomiem ir "zemeņu mēle" - mēle sākotnēji pārklājas ar baltiem plankumiem, bet vēlāk kļūst spilgti sarkana un raupja, līdzīga zemeņu virsmai.',
        slimibasFakts3: 'Mūsdienās skarlatīnu ārstē ar antibiotikām, visbiežāk ar penicilīnu.',
    },
    {
        slimibasId: 2,
        slimibasNosauk: 'Encefalītu',
        slimibasSmagums: 2,
        slimibasFakts1: 'Viens no galvenajiem encefalīta simptomiem ir stīvs kakls, tas palīdz ārstiem atšķirt to no citām smadzeņu slimībām.',
        slimibasFakts2: 'Viena no encefalīta formām, encefalitis lethargica, izraisīja pasaules mēroga epidēmiju no 1917. līdz 1928. gadam un bieži tika dēvēta par "miegainības slimību" tās izraisītās ārkārtējās miegainības dēļ.',
        slimibasFakts3: 'Smagos gadījumos encefalīts var atstāt ilgtermiņa sekas, tostarp smadzeņu bojājumus, kustību traucējumus, intelektuālās vai uzvedības problēmas un citus neuroloģiskus traucējumus.',
    },
    {
        slimibasId: 3,
        slimibasNosauk: 'Dizentēriju',
        slimibasSmagums: 1,
        slimibasFakts1: 'Dizentērijas cēlonis ir parasti baktērija Shigella, it īpaši Shigella dysenteriae. Šī baktērija var iekļūt organismā, galvenokārt caur piesārņotu pārtiku vai ūdeni, un izraisīt slimību.',
        slimibasFakts2: 'Bez pienācīgas ārstēšanas dizentērija var izraisīt nopietnas komplikācijas, piemēram, dehidratāciju, zarnu perforāciju, baktēriju iekļūšanu asinīs (baktēriju sepsis) un citas zarnu slimības.',
        slimibasFakts3: 'Lai novērstu dizentēriju, ir svarīgi ievērot labu higiēnu, rūpīgi mazgāt rokas pēc tualetes lietošanas un pirms ēšanas, kā arī izvairīties no neapstrādāta ūdens un piesārņotas pārtikas patēriņa, it īpaši ceļojot uz valstīm ar zemu higiēnas līmeni.',
    },
    {
        slimibasId: 4,
        slimibasNosauk: 'Gonoreju',
        slimibasSmagums: 1,
        slimibasFakts1: 'Gonoreja ir seksuāli transmisīva slimība, tā var arī tikt nodota no mātes uz jaundzimušo bērnu dzemdību laikā.',
        slimibasFakts2: 'Gonoreja ir viena no visbiežāk sastopamajām seksuāli transmisīvām slimībām pasaulē. Tā var ietekmēt cilvēkus visās vecuma grupās un sociālajos slāņos.',
        slimibasFakts3: 'Gonoreja tiek ārstēta ar antibiotikām, parasti ar vienas devas injekciju un/vai perorāli lietojamām antibiotikām.',
    },
    {
        slimibasId: 5,
        slimibasNosauk: 'Botulismu',
        slimibasSmagums: 1,
        slimibasFakts1: 'Botulisms parasti rodas, ja cilvēks patērē pārtiku vai dzērienu, kurā ir klāt baktēriju radīti botulisma toksīni. ',
        slimibasFakts2: 'Botulisma simptomi parasti iestājas 12-36 stundas pēc inficēšanās un ietver muskuļu vājumu, neskaidru redzi, sajūtu zudumu, runas traucējumus, sāpes vēderā.',
        slimibasFakts3: 'Botulisma ārstēšanai parasti tiek izmantota antitoksīnu terapija, kas palīdzēs apturēt toksīna izplatīšanos organismā.',
    },
    {
        slimibasId: 6,
        slimibasNosauk: 'Masaliņām',
        slimibasSmagums: 2,
        slimibasFakts1: 'Masaliņas, ko dēvē arī par Vācijas masalām, izraisa masaliņu vīruss. Tā ir lipīga vīrusu infekcija, kas novēršama vakcinācijas ceļā.',
        slimibasFakts2: 'Masaliņas slimniekam raksturīgi sarkani izsitumi, kas sākas uz sejas un pēc tam izplatās uz pārējo ķermeni. Citi simptomi ir zemas pakāpes drudzis, kakla iekaisums un pietūkuši limfmezgli. Bieži sastopamas arī locītavu sāpes, īpaši jaunām sievietēm.',
        slimibasFakts3: 'MMR vakcīna ir labākais līdzeklis masaliņu profilaksei. Vakcīnu parasti ievada divās devās, pirmā ~ 12-15 mēnešu vecumā, bet otrā ~ 4-6 gadu vecumā. ',
    },
    {
        slimibasId: 7,
        slimibasNosauk: 'Tuberkulozi',
        slimibasSmagums: 3,
        slimibasFakts1: 'Tuberkuloze ir slimība, kas izplatās gaisā, inficētam cilvēkam klepojot, šķaudot vai runājot, izdalot pilienus, kurus var ieelpot citi.',
        slimibasFakts2: 'Izplatītākie tuberkulozes simptomi ir ilgstošs klepus, sāpes krūtīs un asins vai krēpu atklepošana. Citi simptomi ir vājums, svara zudums, drudzis un svīšana naktī.',
        slimibasFakts3: 'Tuberkulozi izraisa baktērija Mycobacterium tuberculosis. Tas galvenokārt ietekmē plaušas, bet var ietekmēt arī citas ķermeņa daļas, piemēram, nieres, mugurkaulu un smadzenes.',
    },
    {
        slimibasId: 8,
        slimibasNosauk: 'Gripu',
        slimibasSmagums: 1,
        slimibasFakts1: 'Gripu izraisa gripas vīrusi, kas klasificēti trīs galvenajos tipos: A, B un C.  A un B gripa ir sezonālās gripas epidēmijas, kas notiek katru gadu, savukārt C gripa izraisa pastiprinātas elpceļu saslimšanas.',
        slimibasFakts2: 'Izplatītākie gripas simptomi ir drudzis, drebuļi, klepus, kakla iekaisums, iesnas, muskuļu vai ķermeņa sāpes, galvassāpes un nogurums. Dažiem cilvēkiem, īpaši bērniem, var rasties arī vemšana un caureja.',
        slimibasFakts3: 'Gripa izplatās galvenokārt caur elpceļu pilieniem, kad inficēts cilvēks klepo, šķauda vai sarunājas.',
    },
    {
        slimibasId: 9,
        slimibasNosauk: 'Pneimoniju',
        slimibasSmagums: 2,
        slimibasFakts1: 'Pneimoniju var izraisīt baktērijas, vīrusi, sēnītes vai pat pārtikas, šķidruma vai ķimikāliju ieelpošana. Biežākais cēlonis ir baktērija Streptococcus pneumoniae.',
        slimibasFakts2: 'Daži cilvēki ar vieglu pneimoniju var justies pietiekami labi, lai turpinātu ikdienas gaitas. Šo slimības maigāko formu bieži dēvē par “staigājošu pneimoniju”, un to parasti izraisa baktērija Mycoplasma pneumoniae.',
        slimibasFakts3: 'Pneimonija nav tikai cilvēka slimība, tā var skart arī dzīvniekus -  suņus un kaķus, kā arī mājlopus un savvaļas dzīvniekus.',
    },
    {
        slimibasId: 10,
        slimibasNosauk: 'Diftēriju',
        slimibasSmagums: 2,
        slimibasFakts1: 'Difteriju izraisa baktērija Corynebacterium diphtheriae.',
        slimibasFakts2: 'Simptomi parasti sākas 2-5 dienas pēc inficēšanās un ietver kakla iekaisumu, drudzi un pietūkušus dziedzerus.',
        slimibasFakts3: 'Ļoti svarīga ir tūlītēja ārstēšana ar difterijas antitoksīnu un antibiotikām. Antitoksīns neitralizē toksīnu, bet antibiotikas iznīcina baktērijas.',
    },
    {
        slimibasId: 11,
        slimibasNosauk: 'Vējbakām',
        slimibasSmagums: 1,
        slimibasFakts1: 'Vējbakas ir ļoti lipīgas un izplatās tiešā saskarē ar izsitumiem vai pa gaisu ar elpceļu pilieniem, kad inficēts cilvēks klepo vai šķauda.',
        slimibasFakts2: 'Slimība parasti sākas ar drudzi, nogurumu un galvassāpēm, kam seko raksturīgi niezoši izsitumi, kas veido čūlas un galu galā arī kreveles. Izsitumi bieži sākas uz sejas, krūtīm un muguras pirms izplatīšanās uz citām ķermeņa daļām.',
        slimibasFakts3: 'To var arī izplatīt, pieskaroties piesārņotām virsmām un pēc tam pieskaroties sejai.',
    },
    {
        slimibasId: 12,
        slimibasNosauk: 'Trakumsērgu',
        slimibasSmagums: 3,
        slimibasFakts1: 'Trakumsērga ietekmē centrālo nervu sistēmu, izraisot saslimšanu smadzenēs un galu galā nāvi, ja to neārstē nekavējoties.',
        slimibasFakts2: 'Pirmie trakumsērgas simptomi ir līdzīgi gripas simptomiem - drudzis, galvassāpes un vispārējs vājums. Slimībai progresējot, parādās neiroloģiski simptomi, piemēram, halucinācijas, hidrofobija (bailes no ūdens) un bezmiegs.',
        slimibasFakts3: 'Trakumsērga skar visus zīdītājus. Visizplatītākie pārnēsātāji ir sikspārņi, jenoti, skrandas un lapsas savvaļā. Trakumsērgu var slēgt un pārnest arī tādi mājdzīvnieki kā suņi, kaķi un liellopi, ja tie nav vakcinēti.',
    },
];

export default Slimibas;